.task-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.task-execution {
  .app-body > .row {
    margin-left: 0 !important;
  }

  // Header
  &-header {
    display: flex;

    .navbar-back {
      color: $gray-400;
      background: none;
      border: none;
      margin-left: 10px;
    }

    .navbar-brand {
      background-color: transparent !important;
      width: 150px !important;
      @include media-breakpoint-down(md) {
        margin: 0 5px !important;
        position: relative !important;
        left: 0 !important;
      }
      @include media-breakpoint-down(xs) {
        width: 125px !important;
      }
    }

    .navbar-title {
      display: none;
      color: $text-light;
      font-weight: normal;
      @include media-breakpoint-up(md) {
        display: block;
        // font-size: 1em !important;
        font-size: 15px !important;
        // margin-left: 1%;
        position: relative;
        margin-top: 15px;
        width: 150px;
      }
    }

    // Toogle Sidebar
    .navbar-toggle-sidebar {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(0, -50%);
      background: none;
      border: none;
      color: #fff;
      font-weight: normal;
      border-radius: 4px;
      padding: .4rem .7rem;
      outline: none;
      @include media-breakpoint-up(md) {
        display: none;
      }
      &.active {
        background: $gray-700;
      }
    }

    // Info
    .course-progress {
      position: absolute;
      top: 50%;
      right: 10rem;
      transform: translate(0, -50%);
      color: #fff;
      display: none;
      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
.hide{
    @include media-breakpoint-down(sm) {
        display:none;
      }
}
.hide1{
    @include media-breakpoint-down(md) {
        display:none;
      }
}
    //sub-profile
    .sub-profile{
        // display: flex;
        position: absolute;
        right: 1rem;
        cursor: pointer; 
        @media screen and (max-width: 767px) {
            display: none;
        }
        .lead {
            font-size: .9rem;
            font-weight: 300;
        }
    }

    // dropdowmn menu
    .dpdown-menu{
        position: absolute;
        top: 45px;
        right: 15px;
        z-index: 1000;
        font-size: 1rem;
        color: #475060;
        text-align: left;
        background-color: #ffffff;
        border: 1px solid #e4eaef;
        box-shadow: 0 5px 11px 0 rgba(69, 90, 100, 0.1);
        transform: translateY(0px);
    }
  }

  // Body
  &-body {
    min-height: 700px;
    padding-right: 0 !important;
    // position: relative;    
    // @include media-breakpoint-down(sm) {
    //  // padding-bottom: 100px;
    // }
  }

  // Info
  &-info {
    border-bottom: 1px solid $border-color;
    color: $link-color;
    .task-wrapper {
      padding-right: 30px;
      @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
      }
      @include media-breakpoint-up(lg) {
        padding-right: 5px;
      }
    }
  }

  // Content
  &-content {
    min-height: 700px;

    &-header {
      position: relative;
      @include media-breakpoint-up(md) {
        padding-right: 195px;
      }
      .task-pagination {
        display: none;
        @include media-breakpoint-up(md) {
          display: flex;
          width: 175px;
          position: absolute;
          top: 0;
          right: 0;
          .btn {
            min-width: 65px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  // Editor
//   &-editor {
//   }

  // Video Responsive
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  // iframe {
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  // }

  // Pagination
  &-pagination {
    box-shadow: 0 0px 1px -1px rgba(58, 66, 80, 0.1), 0 -1px 1px 0 rgba(58, 66, 80, 0.05),
      0 -1px 3px 0 rgba(58, 66, 80, 0.05);
    position: relative;
    margin-left: -15px;
    @include media-breakpoint-down(sm) {
      position: relative;
      z-index: 9;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      margin-left: 0;
      margin-top: 0;
      padding-top: .4rem !important;
      padding-bottom: .4rem !important;
    }

    &:after {
      @include media-breakpoint-up(md) {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background: $border-color;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
      }
    }

    .task-pagination {
      //Item
      &-item {
        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      // Btn
      &-prev,
      &-next {
        @include media-breakpoint-up(md) {
          background: none;
          padding: 0;
          width: 100%;
          position: relative;
          border: none;
          display: block;
          min-height: 100%;
          display: flex;
          align-items: center;
          font-style: italic;
          color: $text-light;

          &:hover,
          &:focus,
          &:active {
            background: none !important;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            color: $link-color;
          }
        }
      }

      &-prev {
        @include media-breakpoint-up(md) {
          text-align: left;
          padding-left: 20px;
          padding-right: 30px;
          i {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
        }
      }

      &-next {
        @include media-breakpoint-up(md) {
          text-align: right;
          padding-right: 20px;
          padding-left: 30px;
          i {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }
      }

      // Title
      &-title {
        @include media-breakpoint-up(md) {
          display: block;
          width: 100%;
          font-size: 0.95em;
        }
      }

      // Icon
      &-icon {
        @include media-breakpoint-up(md) {
          color: $link-color;
        }
      }

      // Label
      &-label {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  // Sidebar
  &-sidebar {
    background: $body-bg;
    position: relative;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-left: 1px solid $border-color; 
    overflow-y: auto; 
    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 48px;
      left: 0;
      z-index: 10;  
      height: 100%;
      padding-bottom: 50px;
    }
  }

  .task-execution-toggle-sidebar {
    background: $body-bg;
    display: inline-block;
    padding: 1px 8px;
    font-size: 25px;
    border-radius: 4px 0 0 4px;
    border: 1px solid $border-color;
    border-right: none;
    position: absolute;
    top: 14px;
    right: 0;
    color: $link-color;
    z-index: 1;
    outline: none;
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    i {
      position: relative;
      top: -2px;      
    }
  }

  // Show Sidebar ?
  &.showSidebar {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
    .task-execution-toggle-sidebar {
      right: -1px;
      padding: 1px 5px;
    }
  }
}
